:root[data-theme='light'] {
  --color-background: #F8F8F8;      /* main background */
  --color-text: #333333;
  --color-text-secondary: #555555;  /* for less prominent text */
  --color-accent-increase: #4CAF50;
  --color-accent-decrease: #E53935;
  --color-neutral: #BDBDBD;
  --color-surface: #FFFFFF;         /* cards, papers, raised elements */
  --color-border: #DDDDDD;          /* divider lines, borders */
  --color-hover: #EEEEEE;           /* hover background for menu items */
  --color-icon: #333333;            /* icon colors */
  /* ... other vars */
}

:root[data-theme='dark'] {
  --color-background: #121212;      /* main background */
  --color-text: #E0E0E0;
  --color-text-secondary: #A0A0A0;
  --color-accent-increase: #66BB6A;
  --color-accent-decrease: #FF6F61;
  --color-neutral: #424242;
  --color-surface: #1E1E1E;         /* slightly lighter than background for surfaces */
  --color-border: #333333;          /* divider lines, borders */
  --color-hover: #2A2A2A;           /* hover background for menu items */
  --color-icon: #E0E0E0;            /* icon colors */
  /* ... other vars */
}

