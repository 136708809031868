

.page-wrapper {
  /* min-height: 100vh;
  width: 100%; */
  background-color: #101820;
  margin: 0;
  padding: 0;
  display: block;
  unicode-bidi: isolate;
  box-sizing: border-box;
  position: relative;
}

.main-wrapper {
  justify-content: flex-start;
  display: block;
  unicode-bidi: isolate;
}

.section_hero {
  justify-content: space-between;
  display: block;
  unicode-bidi: isolate;
}

.tabs {
  width: 100%;
  height: 100svh;
  max-width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.tabs_tab {
  width: 100%;
  background-color: #101820;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 9.4svh 2.5rem 2.083svh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tabs-tab-content {
    grid-column-gap: 4.13rem;
    grid-row-gap: 4.13rem;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

.tabs_tab-content-left {
  flex: none;
}

.heading-style-h3 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: .0625rem;
  color:#EAEAEA ;
  margin-top: 0;
  margin-bottom: 0;
}

.tabs_tab-content-right {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
  top: 0%;
  left: 0%;
}

.tabs_tab-heading {
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

.heading-style-h1 {
  text-transform: uppercase;
  flex: none;
  font-size: 8.75rem;
  font-weight: 300;
  line-height: 120%;
  color: #EAEAEA;
}

.tabs_tab.closed {
  width: 8.44rem;
  text-align: center;
  padding: 0rem 1.56rem 3.75rem 1.56rem;
  align-items: center;
}

.is-2 {
  background-color: #1B2735;
}
.is-3 {
  background-color: #283845;
}
