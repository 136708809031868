

.login_page {
  width: 100%;
  height: 100svh;
  max-width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.section_login {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 9.4svh 2.5rem 2.083svh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section_form{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.form-login {
  display: flex;
  flex-direction: column;
  width: 25%;
  row-gap: 1rem;
}


.label-login {
  color:#EAEAEA
}

.input-login{
  background-color: #EAEAEA;
  color: #101820;
  font-family: Poppins, sans-serif;
}

.button-login {
  font-family: Poppins, sans-serif;
  background-color: #E63946;
  color: #EAEAEA;
  cursor: pointer;
  padding: .5rem .41rem;
  position: relative;
  z-index: 3;
}

.error-message {
  color: #EAEAEA;
}
