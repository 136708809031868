

.padding-section-huge {
  padding-top: 8.25rem;
  padding-bottom: 8.12rem;
}

.padding-global {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.container_flext-hrzntl.align-end {
  align-items: flex-end;
}

.container_flext-hrzntl {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.heading-style-h2 {
  font-size: 4.38rem;
  font-weight: 300;
  line-height: 110%;
}

.text-weight-medium {
  font-weight: 600;
}

p {
  letter-spacing: .0625rem;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 140%;
}


.slider_wrapper {
  height: 28.75rem;
  margin-top: 5rem;
  display: flex;
}

.swiper-horizontal {
  touch-action: pan-y;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}
.slider_collection-list-wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}

.slider_collection-list {
  width: 100%;
  height: 100%;
  display: flex;
}

.footer{
  background-color: #E76F51;
}

.padding-section-footer {
  padding-top: 2.5rem;
  padding-bottom: 3.75rem;
}

.container-footer {
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.margin-bottom {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.margin-2-5 {
  margin: 2.5rem;
}

.heading-style-h3 {
  font-size: 2rem;
  color: #101820;
  font-weight: 300;
  line-height: 120%;
}

.connect_wrapper {
  grid-column-gap: 3.38rem;
  grid-row-gap: 3.38rem;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 6.25rem;
  display: flex;
}

.button.service-page {
  margin-left: 0;
}

.button {
  width: 17.8rem;
  border-top: .06rem solid #101820;
  color: #101820;
  justify-content: space-between;
  align-items: center;
  padding-top: .38rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.social_wrapper {
  grid-column-gap: 1.75rem;
  grid-row-gap: 1.75rem;
  display: flex;
}

.footer_links {
  color: #1B2735;
  text-decoration: none;
}

.column_right-footer {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  flex-direction: row;
  align-self: flex-end;
  align-items: flex-end;
  display: flex;
}

.heading-style-h1-projects {
  text-transform: uppercase;
  color: #101820;
  flex: none;
  font-family: Polysans, sans-serif;
  font-size: 8.75rem;
  font-weight: 300;
  line-height: 120%;
}

.text-align-right {
  text-align: right;
}
