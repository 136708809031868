.dashboard_page {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--color-background);
  color: var(--color-text);
  transition: background-color 0.2s ease, color 0.2s ease;
}

.headline {
  font-family: 'Poppins', sans-serif;
  font-size: 2.75rem;
  font-weight: 300;
  line-height: 120%;
  color: var(--color-text);
}

.message {
  font-family: 'Poppins', sans-serif;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 120%;
  color: var(--color-text-secondary);
}
