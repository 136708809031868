.navigation {
  z-index: 1002;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  display: block;
}

.padding-global {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.padding-section-nav {
  padding-top: 3.333svh;
  padding-bottom: .444svh;
}

.container-nav {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.main_link {
  color: #EAEAEA;
  text-decoration: none;
}

.caption {
  letter-spacing: .04rem;
  text-transform: none;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 2000;
}

.text-weight-medium {
  font-weight: 600;
}

.menu_btn{
  background-color: #E63946;
  color: #EAEAEA;
  cursor: pointer;
  padding: .5rem .41rem;
  position: relative;
  z-index: 3;
}

.text-color-alternate {
  color: #EAEAEA;
}

.menu_wrap {
  display: flex;
  z-index: 1;
  width: 100%;
  height: 100svh;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 3.333svh;
  padding-right: 2.5rem;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;

  box-sizing: border-box;
}

.menu_base{
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #101820;
  opacity: .3;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.menu_contain {
  z-index: 2;
  grid-column-gap: .75rem;
  grid-row-gap: 1.00rem;
  flex-direction: column;
  padding: 4.5rem 3.56rem 1.88rem 1.25rem;
  display: flex;
  position: relative;
  background-color: #E63946;
}

.menu_link {
  z-index: 2;
  color: #EAEAEA;
  font-size: 2rem;
  font-weight: 300;
  line-height: 120%;
  text-decoration: none;
  transition: color .2s;
  position: relative;

  box-sizing: border-box;
}

.menu_link:hover{
  color: #101820;
}

/* CSS for animations in Navbar.css */
.grow-in {
  animation: growIn 1.0s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

.shrink-out {
  animation: shrinkOut 1.0s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

@keyframes growIn {
  0% {
    width: 50px;
    height: 50px;
    opacity: 0;
    transform: scale(0.5);
    transform-origin: top right;
  }
  100% {
    width: 195px;
    height: 200px;
    padding: 4.5rem 3.56rem 1.88rem 1.25rem;
    opacity: 1;
    transform: scale(1);
    transform-origin: top right;
  }
}

@keyframes shrinkOut {
  0% {
    width: 195px;
    height: 200px;
    padding: 4.5rem 3.56rem 1.88rem 1.25rem;
    opacity: 1;
    transform: scale(1);
    transform-origin: top right;
  }
  100% {
    width: 50px;
    height: 50px;
    opacity: 0;
    transform: scale(0.5);
    transform-origin: top right;
  }
}

.option-fade-in {
  animation: optionFadeIn 0.8s ease-in-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
  transform: translateY(20px);
}

.option-fade-in:nth-child(2) {
  animation-delay: 0.2s;
}

.option-fade-in:nth-child(3) {
  animation-delay: 0.4s;
}

.option-fade-in:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes optionFadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
  0% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
}

.menu_wrap:hover .menu_contain {
  display: flex;
}
